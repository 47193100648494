import React from "react";
import { graphql } from 'gatsby';
import ContentBlock from '../components/contentBlock/contentBlock';
import {four0four} from './scssModules/404.module.scss';

const NotFoundPage = ({data}) => {
  
  const {datoCmsGlobalFourohfourMessage} = data || {};
  return (
    <div className={`container ${four0four}`}>
      <ContentBlock headingTag={`h1`} linkclass={`btn--tertiary`} data={datoCmsGlobalFourohfourMessage?.content[0]} />
    </div>
  )
}

export default NotFoundPage;

export const query = graphql`
  query($locale : String!) {
    datoCmsGlobalFourohfourMessage(locale: {eq: $locale}, location: {eq: "404"}){
      content{
        ...HeadingCopyLinkFragment
      }
    }
  }`